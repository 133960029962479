import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import SEO from "../components/seo"
import Header from "../components/header"
import Navbar from "../components/navBar"
import Footer from "../components/footer"

  const NotFoundPage = ({ location, pageInfo }) => {

    return (
      <Container fluid className="p-0 main">
      <Row noGutters className="justify-content-center">
        <Col>
          <SEO title="404: Not found" />
          <Header siteTitle={SEO.title} />
        </Col>
      </Row>
      <Navbar pageInfo={pageInfo} />
      <Row noGutters>
        <Col>
          <h1 className="p-5">NOT FOUND</h1>
          <p className="mb-5 p-5">You just hit a page that doesn&#39;t exist...</p>
        </Col>
      </Row>
      <Row noGutters>
        <Col className="footer-col">
          <Footer />
        </Col>
      </Row>
    </Container>
    )
  }

export default NotFoundPage
